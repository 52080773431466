const initialState = {
  storeList: [],
  showScheduleModal: false,
  showPMAssignModal: false,
  isEdit: false,
  selectedSchedule: {},
  pmList: [],
  serverList: [],
  availableServerList: [],
  cdsData: {},
  pmScheduleFormData: {},
  allowedJobTitles: [],

  stopAgent: '',
  startAgent: '',
  changeNumber: '',
  showPMDelete: false,
  pmReportData: [],
  activeTabView: '',
  isUpdatingPMServerList: false,
  vmServersInfo: {
    nutanix_exist: false,
    sli_exist: false,
  },
  listState: {
    activePMType: 'Power Maintenance',
    activeTab: 'active',
    viewType: 'list',
  },
  pmListPaginated: [],
  showHandoverModal: false,
  serviceOfficeList: [],
};

const reducer = (state = initialState, action) => {
  const type = action.type;

  switch (type) {
    case 'SET_PM_SCHEDULE_FORM_DATA':
      return {
        ...state,
        pmScheduleFormData: {
          ...state.pmScheduleFormData,
          ...action.payload,
          // [action.payload.page]: action.payload.formData
        },
      };
    case 'CLEAR_PM_SCHEDULE_FORM_DATA':
      return {
        ...state,
        pmScheduleFormData: {},
      };
    case 'SET_PM_IS_EDIT':
      return {
        ...state,
        isEdit: action.payload,
      };
    case 'SET_SHOW_PM_SCHEDULE_MODAL':
      return {
        ...state,
        showScheduleModal: action.payload.show,
        isEdit: action.payload.isEdit,
      };
    case 'SET_SHOW_PM_ASSIGN_MODAL':
      return {
        ...state,
        showPMAssignModal: action.payload,
      };
    case 'SET_PM_SELECTED_SCHEDULE': {
      return {
        ...state,
        selectedSchedule: action.payload,
      };
    }
    case 'SET_PM_LIST':
      return {
        ...state,
        pmList: action.payload,
      };
    case 'SET_PM_SERVER_LIST':
      return {
        ...state,
        serverList: action.payload,
      };
    case 'SET_PM_AVAILABLE_SERVER_LIST':
      return {
        ...state,
        availableServerList: action.payload,
      };
    case 'SET_SHOW_PM_SUMMARY_MODAL':
      return {
        ...state,
        showPMSummary: action.payload.show,
      };
    case 'SET_SHOW_PM_HISTORY_MODAL':
      return {
        ...state,
        showPMHistory: action.payload.show,
      };
    case 'SET_SHOW_PM_DELETE_MODAL':
      return {
        ...state,
        showPMDelete: action.payload.show,
      };
    case 'SET_SHOW_PM_SERVER_DETAILS_MODAL':
      return {
        ...state,
        showPMServerDetails: action.payload.show,
      };
    case 'SET_PM_CDS_DATA':
      return {
        ...state,
        cdsData: action.payload,
      };
    case 'SET_PM_STOP_AGENT':
      return {
        ...state,
        stopAgent: action.payload,
      };
    case 'SET_PM_START_AGENT':
      return {
        ...state,
        startAgent: action.payload,
      };
    case 'SET_PM_CHANGE_NUMBER':
      return {
        ...state,
        changeNumber: action.payload,
      };
    case 'SET_SHOW_PM_HANDOVER_MODAL':
      return {
        ...state,
        showHandoverModal: action.payload,
      };
    case 'SET_PM_FETCHING_FLAG': {
      let apiType = action.payload.apiType;
      let value = action.payload.value;
      switch (apiType) {
        case 'getCDSUserDetails': {
          return {
            ...state,
            fetchingCDSData: value,
          };
        }
        case 'schedulePM': {
          return {
            ...state,
            savingPM: value,
          };
        }
        case 'updatePM': {
          return {
            ...state,
            savingPM: value,
          };
        }
        case 'deletePM': {
          return {
            ...state,
            deletingPM: value,
          };
        }
        case 'getPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getAllPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getAssignedPMList': {
          return {
            ...state,
            fetchingPMList: value,
          };
        }
        case 'getServerList': {
          return {
            ...state,
            fetchingServerList: value,
          };
        }
        case 'assignAgent': {
          return {
            ...state,
            assigningAgent: value,
          };
        }
        case 'saveAgent': {
          return {
            ...state,
            savingAgent: value,
          };
        }
        case 'getCurrentPMForAdmin':
        case 'getCurrentPM': {
          return {
            ...state,
            fetchingCurrentPM: value,
          };
        }
        case 'triggerBuild': {
          return {
            ...state,
            triggeringBuild: value,
          };
        }
        case 'getPMBuildStatus': {
          return {
            ...state,
            fetchingBuildStatus: value,
          };
        }
        case 'getServerPingStatus': {
          return {
            ...state,
            fetchingServerPingStatus: value,
          };
        }
        case 'generateOTPForPM': {
          return {
            ...state,
            sendingOTP: value,
          };
        }
        case 'validateOTPForPM': {
          return {
            ...state,
            validatingOTP: value,
          };
        }
        case 'startPMWithoutTrigger': {
          return {
            ...state,
            startingPMOperation: value,
          };
        }
        case 'triggerPM': {
          return {
            ...state,
            isFetchingTriggerPM: value,
          };
        }
        case 'updatePMScheduleServerList': {
          return {
            ...state,
            isUpdatingPMServerList: value,
          };
        }
        case 'closeChangeRequest': {
          return {
            ...state,
            isClosingChangeRequest: value,
          };
        }
        case 'updateSupportContacts': {
          return {
            ...state,
            isUpdatingSupportContacts: value,
          };
        }
        case 'triggerGDHStep': {
          return {
            ...state,
            isLoadingGDHStep: value,
          };
        }
        case 'triggerGDHStartupStepNT': {
          return {
            ...state,
            isLoadingGDHNtx: value,
          };
        }
        case 'triggerGDHStartupStepSLI': {
          return {
            ...state,
            isLoadingGDHSli: value,
          };
        }
        default:
          return { ...state };
      }
    }
    case 'SET_PM_ACTIVE_TAB_VIEW_NAME':
      return {
        ...state,
        activeTabView: action.payload,
      };

    case 'SET_PM_SITE_VM_SERVERS_STATUS':
      return {
        ...state,
        vmServersInfo: action.payload,
      };
    case 'SET_PM_LIST_ACTIVE_TAB':
      return {
        ...state,
        listState: { ...state.listState, activeTab: action.payload },
      };
    case 'SET_PM_LIST_ACTIVE_TYPE':
      return {
        ...state,
        listState: { ...state.listState, activePMType: action.payload },
      };
    case 'SET_PM_LIST_VIEW_TYPE':
      return {
        ...state,
        listState: { ...state.listState, viewType: action.payload },
      };
    case 'SET_SERVICE_OFFICE_LIST':
      return {
        ...state,
        serviceOfficeList: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
